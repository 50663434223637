<template>
  <div class="finance-overlay">
    <router-view v-if="loaded"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'FinanceOverlay',
  data: () => {
    return {
      loaded: false,
    }
  },
  created() {
    this.$loading()
    Promise.all([
      this.getAccount(),
      this.getTransactionCategory('in'),
      this.getTransactionCategory('out'),
      this.getCustomTransactionCategory('in'),
      this.getCustomTransactionCategory('out'),
    ]).finally(() => {
      this.loaded = true
      this.$loading(false)
    })
  },
  methods: {
    ...mapActions('finance', [
      'getAccount',
      'getTransactionCategory',
      'getCustomTransactionCategory',
    ]),
  }
}
</script>
